import React from "react";
import { Menu, Image, Container, Grid } from "semantic-ui-react";
import MetamaskProvider, { INetwork } from "./Metamask/MetamaskProvider";
import Networks from "./Metamask/Networks";
// import MobileNavBar from "./mobilePages/MobileNavBar";
import { useMediaQuery } from "react-responsive";

import { useState } from "react";
import { Menu as MenuMobile, Item } from "burger-menu";
import "burger-menu/lib/index.css";
function handleItemClick(amountToScroll: number): void {
  window.scrollTo(0, amountToScroll);
}
export default function NavBar() {
  const [connected, setConnected] = React.useState(MetamaskProvider.connected());
  const [address, setAddress] = React.useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleConnect = async () => {
    if (MetamaskProvider.get() === undefined) {
      window.location.href = ("https://metamask.app.link/dapp/nftaudibg.io");
      return;
    }
    await MetamaskProvider.connect(Networks.matic as INetwork);
    setAddress(await MetamaskProvider.get().signer.getAddress());
    setConnected(true);
  };

  const handleBBBB = () => {
    console.warn(isOpen);
    setIsOpen(false);
    console.warn(isOpen);
  };
  const firstTime = async () => {
    if (MetamaskProvider.get() === undefined) return;
    await MetamaskProvider.connect(Networks.matic as INetwork);
    setAddress(await MetamaskProvider.get().signer.getAddress());
    setConnected(true);
  };
  firstTime();

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
  return (
    <>
      {isTabletOrMobile && (
        <>
          <div onClick={() => setIsOpen(!isOpen)}>
            <div
              id="topMobile"
              style={{
                height: "4rem",
                width: "100%",
                backgroundColor: "white",
                position: "relative",
                margin: "auto",
              }}
            >
              <Container
                style={{
                  margin: "auto",
                  paddingTop: "1.6rem",
                  paddingBottom: "2.6rem ",
                }}
              >
                <div style={{ margin: "auto", position: "relative" }}>
                  <div style={{ margin: "auto" }}>
                    <Image
                      src={"assets/audi-logo.png"}
                      style={{ height: "1.5rem", margin: "auto" }}
                    />
                  </div>
                  <Image
                    src="assets/threeLines.png"
                    style={{ height: "1.5rem", paddingLeft: "1rem", marginTop: "-20px" }}
                  />
                </div>
              </Container>
            </div>
          </div>
          <MenuMobile
            width="93vw"
            className="burger-menu"
            isOpen={isOpen}
            selectedKey={"entry"}
            onClose={() => setIsOpen(false)}
            side="left"
            customCrossIcon={<></>}
          >
            <Grid
              style={{
                marginBottom: "-2.4rem",
                marginLeft: "-3rem",
              }}
            >
              <Grid.Row columns={2}>
                <Grid.Column width={3}>
                  <img
                    onClick={() => {
                      setIsOpen(false);
                    }}
                    src={"./assets/x.png"}
                    style={{
                      height: "31px",
                      marginTop: "1.4rem",
                      marginLeft: "45px",
                    }}
                  />
                </Grid.Column>
                <Grid.Column width={2}>
                  <p
                    className="ExtendedNormal"
                    style={{ marginTop: "5rem,", marginLeft: "-6rem", paddingLeft: "-3rem" }}
                  >
                    Menu
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <br />
            <hr />
            {/* <Item itemKey={"home"} text={"Home"}></Item> */}
            <a href="top" style={{ color: "black" }}>
              <p className="ExtendedNormal">
                <button
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  Home
                </button>
              </p>
            </a>
            <a
              href="#aboutMobile"
              style={{ color: "black" }}
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <p className="ExtendedNormal">
                <button
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  About
                </button>
              </p>
            </a>
            <a href="#nftMobile" style={{ color: "black" }}>
              <p className="ExtendedNormal">
                <button
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  Artists
                </button>
              </p>
            </a>
            <a href="#helpMobile" style={{ color: "black" }}>
              <p className="ExtendedNormal">
                <button
                  onClick={() => {
                    console.log(isOpen);
                    setIsOpen(false);
                    console.log(isOpen);
                  }}
                >
                  Help
                </button>
              </p>
            </a>
            <a onClick={handleConnect} style={{ color: "black" }}>
              <p className="ExtendedNormal">Connect to wallet</p>
            </a>
          </MenuMobile>
        </>
      )}
      {isDesktopOrLaptop && (
        <Menu id="top" text style={{ height: "8rem" }}>
          <Container style={{ paddingTop: "2rem", paddingBottom: "2.6rem " }}>
            <Image src={"assets/audi-logo.png"} />
          </Container>
          <Container>
            <Menu.Item href="#about">
              <p style={{ fontSize: "25px", marginLeft: "45px" }}>About</p>
            </Menu.Item>
            <Menu.Item href="#nft">
              <p style={{ fontSize: "25px", marginLeft: "45px" }}>Artists</p>
            </Menu.Item>
            <Menu.Item href="#help">
              <p style={{ fontSize: "25px", marginLeft: "45px" }}>Help</p>
            </Menu.Item>
            {connected && (
              <>
                <Menu.Item style={{ marginLeft: "1rem" }} name="Connect to Wallet">
                  <p style={{ fontSize: "25px", marginLeft: "45px" }}>Address:</p>
                </Menu.Item>
                <Menu.Item name="Connect to Wallet">
                  <p className="truncate" style={{ fontSize: "25px", marginLeft: "5px" }}>
                    {address.substring(0, 3) +
                      "..." +
                      address.substring(address.length - 2, address.length)}
                  </p>
                </Menu.Item>
              </>
            )}
            {!connected && (
              <Menu.Item onClick={handleConnect}>
                <p style={{ fontSize: "25px", marginLeft: "45px" }}>Connect to Wallet</p>
              </Menu.Item>
            )}
          </Container>
        </Menu>
      )}
    </>
  );
}
