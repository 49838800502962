const Networks = {
  bscTestnet: {
    chainId: "0x61",
    name: "Binance Smart Chain Testnet",
    explorer: "https://testnet.bscscan.com",
    nativeCurrency: {
      name: "TBNB",
      symbol: "tbnb",
      decimals: 18,
    },
    rpcUrls: [
      "https://data-seed-prebsc-1-s1.binance.org:8545/",
      "https://data-seed-prebsc-2-s1.binance.org:8545/",
      "https://data-seed-prebsc-1-s2.binance.org:8545/",
      "https://data-seed-prebsc-2-s2.binance.org:8545/",
      "https://data-seed-prebsc-1-s3.binance.org:8545/",
      "https://data-seed-prebsc-2-s3.binance.org:8545/]",
    ],
  },
  mumbai: {
    chainId: "0x13881",
    name: "Mumbai Polygon Testnet",
    explorer: "https://mumbai.polygonscan.com",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: [
      "https://rpc-mumbai.maticvigil.com/", 
    ],
  },
  matic: {
    chainId: "0x89",
    name: "Polygon",
    explorer: "https://polygonscan.com",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: [
      "https://polygon-rpc.com", 
    ],
  },
};

export default Networks;
