import { Image, Container, Header } from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";

export default function Help() {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
  return (
    <>
      {isDesktopOrLaptop && (
        <>
          <Image
            style={{
              width: "fit-content",
              margin: "auto",
              height: "28.5rem",
              padding: "0rem 9.6rem",
            }}
            src={"assets/help.png"}
          />
          <div
            id="help"
            style={{
              backgroundColor: "#e5e5e5",
              marginTop: "5rem",
              marginBottom: "0rem",
              marginLeft: "9.6rem",
              marginRight: "9.6rem",
              paddingLeft: "20rem",
              paddingRight: "20rem",
              paddingTop: "3rem",
              paddingBottom: "7rem",
            }}
          >
            <p style={{ fontSize: "4.5rem", textAlign: "center" }}>Help</p>
            <p style={{ lineHeight: "2.5", textAlign: "left", margin: "auto" }}>
              <br />
              <p className="ExtendedBold" style={{ fontSize: "24px" }}>
                How to claim a free NFT?
              </p>
              <p className="WideNormal" style={{ fontSize: "21px", lineHeight: "2" }}>
                Visit www.metamask.io and download the Metamask Wallet for iOS and Android.
                <br />
                <br />
                Create a Metamask account by following the steps in the app.
                <br />
                <br />
                Once you have created a Metamask account, click on the burger menu and select
                “Browser”. Then in the URL field type: nftaudibg.io
                <br />
                <br />
                On the loaded page click the button “Claim” and confirm the transaction to receive
                your NFT. Your NFT will appear on screen, along with the other NFTs for sale
                <br />
                <br />
                <br />
                <p className="ExtendedBold" style={{ fontSize: "24px" }}>
                  How to visit Audi's NFT gallery in Opensea?
                </p>
                Open up your Metamask wallet and click on the burger menu and select “Browser”. Then
                in the URL field type: Opensea.io/audibulgaria
                <br />
                <br />
                Click on the burger menu at opensea.io/audibulgaria and select “Connect wallet”{" "}
                {">"} Metamask {">"}
                <br />
                Agree with the terms of conditions {">"} click “Х”.
                <br />
                <br />
                Your NFT will appear on screen, along with the other NFTs for sale. <br />
                <br />
                <br />
                <br />
                <p className="ExtendedBold" style={{ fontSize: "24px" }}>
                  How to view your claimed NFT?
                </p>  
                <br />
                Click on the burger menu in Metamask, then select ‘Browser’, type the url: opensea.io/audibulgaria and select “Connect  
                wallet” {">"} Metamask {">"} Agree with the terms of conditions {">"} click “Х”.
                <br /> 
                <br />
              </p>
            </p>
          </div>
          {/* <a href="#top">
            <Image
              style={{
                width: "3rem",
                textAlign: "right",
                marginLeft: "96.3vw",
                borderColor: "red",
                borderWidth: "3px",
                borderStyle: "solid",
              }}
              src={"assets/arrowUp.png"}
            />
          </a> */}
        </>
      )}

      {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILEMOBILE MOBILEMOBILE
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

      {isTabletOrMobile && (
        <>
          <Container
            id="helpMobile"
            style={{
              backgroundColor: "#ffffff",
              marginTop: "2rem",
              paddingLeft: "1.7rem",
              paddingRight: "1.3rem",
            }}
          >
            <p style={{ fontSize: "2rem", textAlign: "center" }}>Help</p>
            <p style={{ lineHeight: "2.5", textAlign: "left" }}>
              <br />
              <p style={{ fontSize: "16px" }}> How to claim a free NFT?</p>
              <p className="WideNormal" style={{ fontSize: "16px", lineHeight: "2" }}>
                Visit www.metamask.io and download the Metamask Wallet for iOS and Android.
                <br />
                You can view your NFT on both Metamask and OpenSea.
                <br />
                Create a Metamask account by following the steps in the app.
                <br />
                Once you have created a Metamask account, click on the burger menu and select
                “Browser”. Then in the URL field type: nftaudibg.io
                <br />
                On the loaded page click the button “Claim” and confirm the transaction to receive
                your NFT.
                <br />
                Your NFT will appear on screen, along with the other NFTs for sale
                <br />
                <br />
                <p className="ExtendedBold" style={{ fontSize: "16px" }}>
                  How to visit Audi's NFT gallery in Opensea?
                </p>
                Open up your Metamask wallet and click on the burger menu and select “Browser”. Then
                in the URL field type: Opensea.io/audibulgaria
                <br />
                Click on the burger menu at opensea.io/audibulgaria and select “Connect wallet”
                {">"} Metamask {">"}
                <br />
                Agree with the terms of conditions {">"} click “Х”.
                <br />
                Your NFT will appear on screen, along with the other NFTs for sale <br />
                <br />
                <br />
                <p className="ExtendedBold" style={{ fontSize: "16px" }}>
                  How to view your claimed NFT?
                </p> 
                <br />
                Click on the burger menu in Metamask, then select ‘Browser’, type the url: opensea.io/audibulgaria and select “Connect  
                wallet” {">"} Metamask {">"} Agree with the terms of conditions {">"} click “Х”.  
                <br />
                <br />
              </p>
            </p>
          </Container>
        </>
      )}
    </>
  );
}
