import { ethers } from "ethers"; 
import { ERC721Mintable__factory, IERC721Mintable__factory, Operator__factory } from "../typechain-types"
import MetamaskProvider, { INetwork } from "./MetamaskProvider";
import Networks from "./Networks"; 
export default class NFTContract {
    static async retrieveImage(address: string, provider: ethers.providers.Web3Provider, tokenId: string): Promise<string> {
        const contract = IERC721Mintable__factory.connect(address, provider);
        const result = await contract.callStatic.tokenURI(tokenId);
        return `https://api.ipfsbrowser.com/ipfs/get.php?hash=${result}`; 
    }

    static async claimNFT(): Promise<string> {  
        if(!MetamaskProvider.connected()){
          await MetamaskProvider.connect(Networks.matic as INetwork);
        }
        const signer = MetamaskProvider.get().signer;  
        const nft = ERC721Mintable__factory.connect("0xb314158f7c233c34757e925cf3ea157b44ca88a6", signer);
        const waited = await(await fetch("https://api.defender.openzeppelin.com/autotasks/c3d13011-ee0c-4170-9b59-7fb9415d3081/runs/webhook/6b753eb6-d99f-4c88-86de-567960d9c721/3ziwgoFdiAvFdWfwSBz9Qf",
        {
          method: 'POST', 
          mode: 'cors', 
          headers:{
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({"requestee": await signer.getAddress()})
        })).json();  
        const tx = JSON.parse(waited.result); 
        const result = await nft.queryFilter(nft.filters["Transfer(address,address,uint256)"](ethers.constants.AddressZero, await signer.getAddress()), tx.blockHash);
        const tokenId = (((ethers.utils.defaultAbiCoder.decode(["uint256"], (result[0].topics[3])))[0]) as ethers.BigNumber).toString();
        //@todo visualize result 
        return tokenId;
    }

    static async userHasNFT(): Promise<boolean> {   
        await MetamaskProvider.connect(Networks.matic as INetwork); 
        const signer = MetamaskProvider.get().signer; 
        const nft = ERC721Mintable__factory.connect("0xb314158f7c233c34757e925cf3ea157b44ca88a6", signer); 
        return (await nft.balanceOf(await signer.getAddress())).toNumber() > 0;
    } 

    static async viewUserNFT(): Promise<{[key:string]: string}> {   
      const signer = MetamaskProvider.get().signer; 
      const result = (await (await fetch(`https://deep-index.moralis.io/api/v2/${await signer.getAddress()}/nft?chain=polygon&format=decimal&normalizeMetadata=false`, {
        headers:{
          "Accept": "application/json",
          "x-api-key": "iOtWVS2gSJkFo1kiLhbeCIJryBJ8Tcv94O1u2BdHBLlwCAPJWle5AToJ1jBnaeda"
        }
      })).json()).result as any[];
      const nft = result.find((entry) => entry.token_address === "0xb314158f7c233c34757e925cf3ea157b44ca88a6" )
      return nft;
    } 
} 