import { Image, Container, Grid } from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";

export default function Footer() {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const goToFB = () => {
    window.location.href = "https://www.facebook.com/Audi.Bulgaria";
  };
  const goToYT = () => {
    window.location.href = "https://www.youtube.com/user/AudiofBulgaria";
  };
  const goToInsta = () => {
    window.location.href = "https://www.instagram.com/audi.bulgaria_official/";
  };
  const goToWeb = () => {
    window.location.href = "https://www.audi.bg/";
  };
  //////////////////////////////////////////////////////////MOBILEEEEEE////////////////////////////////////////////

  if (isTabletOrMobile) {
    return (
      <div style={{ backgroundColor: "black", width: "100%", padding: "2rem" }}>
        <a href="#topMobile">
          <Image
            style={{
              width: "4.4rem",
              textAlign: "right",
              marginRight: "-2rem",
              float: "right",
              marginTop: "-6rem",

              // position: "fixed",
              // bottom: "21px",
              // right: "25px",
            }}
            src={"assets/arrowUp.png"}
          />
        </a>
        <Grid columns={2} relaxed="very" stackable>
          <Grid.Column textAlign="left">
            <a onClick={goToFB} style={{ cursor: "pointer" }} target="_blank">
              <Image className="footerImagesMobile" src={"assets/footerFB.png"} />
            </a>
            <a onClick={goToInsta} style={{ cursor: "pointer" }} target="_blank">
              <Image className="footerImagesMobile" src={"assets/footerInsta.png"} />
            </a>
            <a
              onClick={goToYT}
              style={{ cursor: "pointer", objectFit: "cover", maxWidth: "30px" }}
              target="_blank"
            >
              <Image className="footerImagesMobile" src={"assets/footerYT.png"} />
            </a>
            <a
              onClick={goToWeb}
              style={{ cursor: "pointer", objectFit: "cover", maxWidth: "12px" }}
              target="_blank"
            >
              <Image className="footerImagesMobile" src={"assets/footerWeb.png"} />
            </a>
          </Grid.Column>
          <Grid.Column>
            <br />
            <p
              className="WideNormal"
              style={{ color: "#a8a8a8", marginLeft: "2px", fontSize: "11px" }}
            >
              © 2022. Audi Bulgaria. All rights reserved
            </p>
          </Grid.Column>
        </Grid>
      </div>
    );
    ////////////////////////////////////////////////////////////
  } else {
    return (
      <>
        <a href="#top">
          <Image
            style={{
              width: "4.4rem",
              textAlign: "right",
              margin: "auto",
              marginRight: "0rem",
            }}
            src={"assets/arrowUp.png"}
          />
        </a>
        <Container
          style={{ backgroundColor: "black", width: "100%", padding: "4rem", paddingLeft: "10rem" }}
        >
          <Grid columns={2} relaxed="very" stackable>
            <Grid.Column>
              <br />
              <p className="WideNormal" style={{ color: "#a8a8a8", fontSize: "15px" }}>
                © 2022. Audi Bulgaria. All rights reserved
              </p>
            </Grid.Column>

            <Grid.Column textAlign="right">
              <a onClick={goToWeb} style={{ cursor: "pointer" }} target="_blank">
                <Image
                  style={{ marginRight: "6rem", marginTop: "9px", height: "26px", width: "26px" }}
                  className="footerImages"
                  src={"assets/footerWebDesktop.png"}
                />
              </a>
              <a onClick={goToYT} style={{ cursor: "pointer" }} target="_blank">
                <Image className="footerImages" src={"assets/footerYT.png"} />
              </a>
              <a onClick={goToInsta} style={{ cursor: "pointer" }} target="_blank">
                <Image className="footerImages" src={"assets/footerInsta.png"} />
              </a>
              <a onClick={goToFB} style={{ cursor: "pointer" }} target="_blank">
                <Image className="footerImages" src={"assets/footerFB.png"} />
              </a>
            </Grid.Column>
          </Grid>
        </Container>
      </>
    );
  }
}
