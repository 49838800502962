import { Image, Grid, Pagination, Container } from "semantic-ui-react";
import SingleNftCreator from "./SingleNftCreator";
import { useMediaQuery } from "react-responsive";
import { useState } from "react";

export default function NFT() {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
  const [pageNum, setPageNum] = useState<number>(1);
  return (
    <>
      {isDesktopOrLaptop && (
        <div
          id="nft"
          style={{
            backgroundColor: "#e5e5e5",
            paddingBottom: "1rem",
            maxWidth: "150%",
            width: "auto",
            padding: "5rem 9.6rem 5rem",
            marginBottom: "5rem",
          }}
        >
          <Grid columns={2} relaxed="very" stackable>
            <Grid.Column floated="left" width={4}>
              <p style={{ marginTop: "5rem", fontSize: "55px", marginLeft: "1remz" }}>Artists</p>
            </Grid.Column>
            <Grid.Column floated="left" width={9} style={{ marginRight: "3rem" }}>
              <p
                className="WideNormal"
                style={{ fontSize: "21px", marginTop: "5rem", textAlign: "left" }}
              >
                You can support Bulgarian creators by purchasing one of their NFTs on the Audi
                OpenSea profile. Each NFT is available as a single copy only, so you have the chance
                to become the sole owner of a unique piece of art. 100% of the profit of initial
                purchases and 10% of each resale go directly to the artists.
                <br />
              </p>
            </Grid.Column>
          </Grid>
          <Grid columns={4} relaxed="very" stackable>
            <SingleNftCreator
              imageName={"nft1"}
              creatorName={"stenduc"}
              instagramWebLink="https://www.instagram.com/stenduc/"
              nftWebLink="https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/100278564587405245365916423232495888010088461438227269705457508052852675706881"
            />
            <SingleNftCreator
              imageName={"nft2"}
              creatorName={"to4kaspot"}
              instagramWebLink="https://www.instagram.com/to4kaspot/"
              nftWebLink="https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/100278564587405245365916423232495888010088461438227269705457508051753164079105"
            />
            <SingleNftCreator
              imageName={"nft3"}
              creatorName={"PRAM"}
              instagramWebLink="https://www.instagram.com/boris_pramatarov/"
              nftWebLink="https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/100278564587405245365916423232495888010088461438227269705457508053952187334657"
            />
            <SingleNftCreator
              imageName={"nft4"}
              creatorName={"pllik"}
              instagramWebLink="https://www.instagram.com/pllik/"
              nftWebLink="https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/100278564587405245365916423232495888010088461438227269705457508055051698962433"
            />
          </Grid>
        </div>
      )}

      {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILEMOBILE MOBILEMOBILE
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

      {isTabletOrMobile && (
        <>
          <Image src={"assets/mobileHelp.png"} />

          <div
            id="nftMobile"
            style={{
              backgroundColor: "#e5e5e5",
              paddingBottom: "1rem",
              maxWidth: "150%",
              width: "auto",
            }}
          >
            <p style={{ paddingTop: "4rem", fontSize: "25px", textAlign: "center" }}>Artists</p>

            <p
              className="WideNormal"
              style={{
                paddingRight: "3rem",
                paddingLeft: "3rem",
                fontSize: "16px",
                marginTop: "2.8rem",
                textAlign: "left",
              }}
            >
              You can support Bulgarian creators by purchasing one of their NFTs on the Audi OpenSea
              profile. Each NFT is available as a single copy only, so you have the chance to become
              the sole owner of a unique piece of art. 100% of the profit of initial purchases and
              10% of each resale go directly to the artists.
            </p>
            <div
              style={{
                margin: "auto",
                textDecorationColor: "grey",
                position: "relative",
                width: "100%",
              }}
            >
              <div style={{ margin: "auto", display: "table", width: "50%" }}>
                <div
                  style={{
                    margin: "auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <a
                    className="arrow leftArrow"
                    onClick={() => {
                      setPageNum(pageNum > 1 ? pageNum - 1 : 1);
                    }}
                  />
                  {pageNum == 1 && (
                    <>
                      <a
                        style={{ textDecoration: "underline", fontWeight: "bold" }}
                        onClick={() => setPageNum(1)}
                        className="pageNum"
                      >
                        1
                      </a>
                      <a onClick={() => setPageNum(2)} className="pageNum">
                        2
                      </a>
                      <a onClick={() => setPageNum(3)} className="pageNum">
                        3
                      </a>
                      <a onClick={() => setPageNum(4)} className="pageNum">
                        4
                      </a>
                    </>
                  )}

                  {pageNum == 2 && (
                    <>
                      <a onClick={() => setPageNum(1)} className="pageNum">
                        1
                      </a>
                      <a
                        style={{ textDecoration: "underline", fontWeight: "bold" }}
                        onClick={() => setPageNum(2)}
                        className="pageNum"
                      >
                        2
                      </a>
                      <a onClick={() => setPageNum(3)} className="pageNum">
                        3
                      </a>
                      <a onClick={() => setPageNum(4)} className="pageNum">
                        4
                      </a>
                    </>
                  )}

                  {pageNum == 3 && (
                    <>
                      <a onClick={() => setPageNum(1)} className="pageNum">
                        1
                      </a>
                      <a onClick={() => setPageNum(2)} className="pageNum">
                        2
                      </a>
                      <a
                        style={{ textDecoration: "underline", fontWeight: "bold" }}
                        onClick={() => setPageNum(3)}
                        className="pageNum"
                      >
                        3
                      </a>
                      <a onClick={() => setPageNum(4)} className="pageNum">
                        4
                      </a>
                    </>
                  )}

                  {pageNum == 4 && (
                    <>
                      <a onClick={() => setPageNum(1)} className="pageNum">
                        1
                      </a>
                      <a onClick={() => setPageNum(2)} className="pageNum">
                        2
                      </a>
                      <a onClick={() => setPageNum(3)} className="pageNum">
                        3
                      </a>
                      <a
                        style={{ textDecoration: "underline", fontWeight: "bold" }}
                        onClick={() => setPageNum(4)}
                        className="pageNum"
                      >
                        4
                      </a>
                    </>
                  )}
                  <a
                    className="arrow rightArrow"
                    onClick={() => {
                      setPageNum(pageNum < 4 ? pageNum + 1 : 4);
                    }}
                  />
                </div>
              </div>
            </div>
            <div style={{}}>
              {pageNum == 1 && (
                <SingleNftCreator
                  imageName={"nft1"}
                  creatorName={"stenduc"}
                  instagramWebLink="https://www.instagram.com/stenduc/"
                  nftWebLink="https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/100278564587405245365916423232495888010088461438227269705457508052852675706881"
                />
              )}
              {pageNum == 2 && (
                <SingleNftCreator
                  imageName={"nft2"}
                  creatorName={"to4kaspot"}
                  instagramWebLink="https://www.instagram.com/to4kaspot/"
                  nftWebLink="https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/100278564587405245365916423232495888010088461438227269705457508051753164079105"
                />
              )}
              {pageNum == 3 && (
                <SingleNftCreator
                  imageName={"nft3"}
                  creatorName={"PRAM"}
                  instagramWebLink="https://www.instagram.com/boris_pramatarov/"
                  nftWebLink="https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/100278564587405245365916423232495888010088461438227269705457508053952187334657"
                />
              )}
              {pageNum == 4 && (
                <SingleNftCreator
                  imageName={"nft4"}
                  creatorName={"pllik"}
                  instagramWebLink="https://www.instagram.com/pllik/"
                  nftWebLink="https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/100278564587405245365916423232495888010088461438227269705457508055051698962433"
                />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}
