import { Image, Header, Grid } from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";
import { isModifier } from "typescript";

export default function AboutPage() {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
  return (
    <>
      {isDesktopOrLaptop && (
        <Grid id="about" columns={2} relaxed="very" stackable>
          <Grid.Column>
            <Image
              size="big"
              src={"assets/about.png"}
              style={{
                marginTop: "4rem",
                marginBottom: "5rem",
                marginLeft: "0rem",
                marginRight: "1rem",
              }}
            />
          </Grid.Column>
          <Grid.Column>
            <p
              style={{
                marginTop: "12rem",
                fontSize: "55px",
                textAlign: "left",
                fontSizeAdjust: "55px",
                fontWeight: "bold",
              }}
            >
              About
            </p>
            <p
              className="WideNormal"
              style={{
                fontSize: "21px",
                marginTop: "3rem",
                textAlign: "left",
                fontSizeAdjust: "21px",
                fontWeight: "normal",
                marginRight: "3rem",
              }}
            >
              With Audi’s drive forward into the NFT world, we looked to collaborate with talented
              Bulgarian digital artists to make the Web 3.0 space even more exciting. Each artist
              was given the challenge to represent our philosophy “Future is an attitude” , through
              their unique style.
              <br />
            </p>
          </Grid.Column>
        </Grid>
      )}
      {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILEMOBILE MOBILEMOBILE
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

      {isTabletOrMobile && (
        <>
          <Image size="big" src={"assets/mobileAbout.png"} />

          <div
            id="aboutMobile"
            style={{
              backgroundColor: "white",
              marginLeft: "0rem",
              width: "100%",
              paddingTop: "2rem",
              paddingBottom: "7rem",
              paddingLeft: "3rem",
              paddingRight: "3.3rem",
            }}
          >
            <p style={{ paddingTop: "2rem", fontSize: "25px", textAlign: "center" }}>About</p>
            <p
              className="WideNormal"
              style={{ fontSize: "16px", marginTop: "3rem", textAlign: "left" }}
            >
              With Audi’s drive forward into the NFT world, we looked to collaborate with talented
              Bulgarian digital artists to make the Web 3.0 space even more exciting. Each artist
              was given the challenge to represent our philosophy “Future is an attitude” , through
              their unique style.
            </p>
          </div>
        </>
      )}
    </>
  );
}
