import React from "react";
import { Container, Header, Button } from "semantic-ui-react";
import MetamaskProvider, { INetwork } from "../Metamask/MetamaskProvider";
import Networks from "../Metamask/Networks";
import NFTContract from "../Metamask/NFTContract";
import PopupNft from "../PopupNft";
interface Prop {
  artistName: string;
  nftName: string;
  nftFileName: string;
}

export default class FirstPage extends React.Component<
  { mobile: boolean },
  { backgroundStyle: {}; show: boolean; artistId: number; tokenId: string; hasNft: boolean }
> {
  constructor(props: { mobile: boolean } | Readonly<{ mobile: boolean }>) {
    super(props);
    this.state = {
      backgroundStyle: {
        backgroundImage: "url(assets/firstPage.png)",
        fontSize: "50px",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        textAlign: "left",
      },
      show: false,
      artistId: 0,
      tokenId: "",
      hasNft: false,
    };
    this.claim = this.claim.bind(this);
    this.viewUserNFT = this.viewUserNFT.bind(this);
    setTimeout(() => {
      this.userHasNFT();
    }, 500);

    //@todo use it VASEEEEE
    //this.props.mobile........
    //this.setState({backgroundStyle: {...this.state.backgroundStyle}});
  }

  async claim() {
    if (MetamaskProvider.get() === undefined) {
      window.location.href = ("https://metamask.app.link/dapp/nftaudibg.io");
      return;
    }
    if (!MetamaskProvider.connected()) {
      await MetamaskProvider.connect(Networks.matic as INetwork);
      await this.userHasNFT();
      if (this.state.hasNft) return;
    }
    this.setState(() => ({
      show: true,
    }));
    await MetamaskProvider.connect(Networks.matic as INetwork);
    const result = await NFTContract.claimNFT();
    if (result === "") return null;
    this.setState((state) => ({
      backgroundStyle: state.backgroundStyle,
      artistId: (parseInt(result) % 4) + 1,
      tokenId: result,
    }));
  }
  async userHasNFT() {
    const result = await NFTContract.userHasNFT();
    if (result === true)
      this.setState((state) => ({
        backgroundStyle: state.backgroundStyle,
        show: state.show,
        artistId: state.artistId,
        hasNft: Boolean(result),
      }));
  }
  async viewUserNFT() {
    const result = await NFTContract.viewUserNFT();
    window.location.href = (
      `https://opensea.io/assets/matic/${result.token_address}/${result.token_id}`
    );
  }
  render() {
    return (
      <>
        {!this.props.mobile && (
          <Container style={this.state.backgroundStyle} className="menu-image-block">
            <p
              id="font"
              style={{
                fontSize: "67px",
                marginTop: "3rem",
                color: "white",
                letterSpacing: ".2rem",
              }}
            >
              Future is an attitude
            </p>
            <p id="font" style={{ fontSize: "23px", color: "white" }}>
              Accelerating the NFT world...
              <br />
              Join the ride with Audi and the talented force of our favorite
              <br /> Bulgarian digital artists.
              <br />
              <br />
              Claim your free NFT and hold your breath… The one that’s meant
              <br /> for you is on its way!
              <br />
            </p>
            <>
              {!this.state.show && !this.state.hasNft && (
                <p id="font" style={{ marginTop: "6rem", fontSize: "45px", color: "white" }}>
                  Get your <br />
                  free NFT
                </p>
              )}
              {(this.state.show || this.state.hasNft) && (
                <p id="font" style={{ marginTop: "6rem", fontSize: "45px", color: "white" }}>
                  You already claimed <br />
                  your NFT!
                </p>
              )}
              {(this.state.show || this.state.hasNft) && (
                <Button className="aboutButton" size="massive" onClick={this.viewUserNFT}>
                  <p style={{ fontSize: "23px" }}>View NFT</p>
                </Button>
              )}
              {!this.state.hasNft && !this.state.show && (
                <Button className="aboutButton" size="massive" onClick={this.claim}>
                  <p style={{ fontSize: "23px" }}>Claim</p>
                </Button>
              )}
              <PopupNft
                show={this.state.show}
                artist={this.state.artistId}
                token={this.state.tokenId}
                isMobile={this.props.mobile}
              />
            </>
            {!this.state.hasNft && !this.state.show && (
              <p
                id="font"
                className="ExtendedItalic"
                style={{
                  marginLeft: "5.6rem",
                  color: "white",
                  fontSize: "1rem",
                  marginTop: "1.2rem",
                }}
              >
                *NO GAS FEES
              </p>
            )}
            {this.state.hasNft ||
              (this.state.show && (
                <p
                  id="font"
                  className="ExtendedItalic"
                  style={{
                    color: "white",
                    fontSize: "1rem",
                    marginTop: "1.2rem",
                  }}
                >
                  You are going to be redirected to your OpenSea account
                </p>
              ))}
          </Container>
        )}
        {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILEMOBILE
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        {this.props.mobile && (
          <>
            <div className="tiledBackground">
              <p
                id="font"
                style={{
                  paddingTop: "4rem",
                  paddingLeft: "1rem",
                  color: "white",
                  letterSpacing: ".01rem",
                  fontSize: "24px",
                }}
              >
                Future is an attitude
              </p>

              <>
                {!this.state.hasNft && !this.state.show && (
                  <p
                    id="font"
                    style={{
                      paddingTop: "5rem",
                      paddingLeft: "2rem",
                      fontSize: "25px",
                      color: "white",
                    }}
                  >
                    Get your <br />
                    free NFT
                  </p>
                )}
                {(this.state.show || this.state.hasNft) && (
                  <p
                    id="font"
                    style={{
                      paddingTop: "5rem",
                      paddingLeft: "2rem",
                      fontSize: "1.5rem",
                      color: "white",
                    }}
                  >
                    You already <br /> claimed <br />
                    your NFT <br />
                  </p>
                )}
                {!this.state.hasNft && !this.state.show && (
                  <Button
                    className="aboutButtonMobile"
                    size="large"
                    content="Claim"
                    onClick={this.claim}
                  />
                )}
                {(this.state.show || this.state.hasNft) && (
                  <Button
                    className="aboutButtonMobile"
                    size="large"
                    content="View NFT"
                    onClick={this.viewUserNFT}
                  />
                )}
                <PopupNft
                  show={this.state.show}
                  artist={this.state.artistId}
                  token={this.state.tokenId}
                  isMobile={this.props.mobile}
                />
              </>
              {!this.state.hasNft && !this.state.show && (
                <p
                  className="WideNormal"
                  style={{
                    paddingLeft: "2.2rem",
                    paddingBottom: "1.5rem",
                    color: "white",
                    fontSize: "10px",
                    marginTop: "1.2rem",
                  }}
                >
                  *NO GAS FEES
                </p>
              )}
              {(this.state.show || this.state.hasNft) && (
                <p
                  className="WideNormal"
                  style={{
                    paddingLeft: "2.2rem",
                    paddingBottom: ".5rem",
                    color: "white",
                    fontSize: "10px",
                    marginTop: ".5rem",
                  }}
                >
                  You are going to be redirected to your OpenSea account
                </p>
              )}
            </div>
            <div
              style={{
                backgroundColor: "black",
                marginLeft: "0rem",
                width: "100%",
                paddingTop: "4rem",
                paddingBottom: "2rem",
                paddingLeft: "2rem",
                paddingRight: "2.5rem",
                marginBottom: "-1rem",
              }}
            >
              <p id="font" style={{ fontSize: "16px", color: "white" }}>
                Accelerating to the
                <br /> NFT world... <br />
                Join the ride with Audi and the talented force of our favorite Bulgarian digital
                artists.
                <br />
                <br />
                Claim your free NFT and hold your breath… The one that’s meant for you is on its
                way!
              </p>
            </div>
          </>
        )}
      </>
    );
  }
}
