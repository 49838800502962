import React from "react";
import { Image, Modal } from "semantic-ui-react";
import { SpinnerCircularFixed } from "spinners-react";
declare global {
  interface Window {
    opened: boolean;
  }
}

export default class PopupNft extends React.Component<
  { show: boolean; artist: number; token: string; isMobile: boolean },
  { open: boolean; artistName: string; nftName: string; nftFileName: string }
> {
  constructor(
    props:
      | { show: boolean; artist: number; token: string; isMobile: boolean }
      | Readonly<{ show: boolean; artist: number; token: string; isMobile: boolean }>
  ) {
    super(props);
    this.state = {
      // open: true,
      // artistName: "dasd",
      // nftName: "ddd",
      // nftFileName: "nft1.png",
      open: false,
      artistName: "",
      nftName: "",
      nftFileName: "",
    };
  }
  componentDidUpdate() {
    if (this.props.show && !this.state.open && !window.opened) {
      this.setState((state) => ({ open: true }));
      window.opened = true;
    }
    if (this.props.artist !== 0 && this.state.artistName === "") {
      switch (this.props.artist) {
        case 1:
          this.setState((state) => ({
            artistName: "stenduc",
            nftName: "Protection",
            nftFileName: "popUpNft1.jpg",
          }));
          break;

        case 2:
          this.setState((state) => ({
            artistName: "to4kaspot",
            nftName: "O2",
            nftFileName: "popUpNft2.jpg",
          }));
          break;

        case 3:
          this.setState((state) => ({
            artistName: "PRAM",
            nftName: "Cyber Shaman",
            nftFileName: "popUpNft3.jpg",
          }));
          break;

        case 4:
          this.setState((state) => ({
            artistName: "pllik",
            nftName: "Electromagnetism",
            nftFileName: "popUpNft4.jpg",
          }));
          break;
      }
    }
  }
  render() {
    // if (false) {
    if (this.props.artist !== 0) {
      if (!this.props.isMobile) {
        return (
          <Modal
            className="sharpCorners"
            style={{ height: "45vw", width: "50vw", maxWidth: "80rem", maxHeight: "66rem" }}
            onClose={() => this.setState((state) => ({ open: false }))}
            onOpen={() => this.setState((state) => ({ open: true }))}
            open={this.state.open}
            dimmer={"blurring"}
          >
            <a
              onClick={() => {
                this.setState(() => ({ open: false }));
              }}
            >
              <Image style={{ height: "3rem", float: "right" }} src={`assets/x.png`} />
            </a>
            <Modal.Content>
              <p
                style={{
                  fontSize: "2rem",
                  textAlign: "center",
                  marginTop: "3rem",
                }}
              >
                Congratulations!
              </p>
              <p style={{ fontSize: "1.5rem", textAlign: "center", marginTop: "4rem" }}>
                You received "{this.state.nftName}" <br />
                by {this.state.artistName}
              </p>
              <div
                style={{
                  backgroundColor: "#f2f2f2",
                  width: "34rem",
                  height: "34rem",
                  textAlign: "center",
                  margin: "auto",
                  // marginLeft: "16rem",
                  // marginRight: "20rem",
                  marginTop: "2rem",
                }}
              >
                <Image
                  style={{
                    width: "34rem",
                    height: "34rem",
                  }}
                  src={`assets/${this.state.nftFileName}`}
                />
                <br />
                <div style={{ margin: "auto", position: "relative" }}>
                  <div style={{ margin: "auto" }}>
                    <a
                      style={{ color: "black" }}
                      href={
                        "https://opensea.io/assets/matic/0xb314158f7c233c34757e925cf3ea157b44ca88a6/" +
                        this.props.token
                      }
                    >
                      <div style={{ marginLeft: "-3rem" }} className="popUpButtonDesktop">
                        View your NFT in OpenSea
                      </div>
                    </a>
                    <a
                      style={{ color: "black" }}
                      href="https://opensea.io/collection/future-is-an-attitude"
                    >
                      <div style={{ marginRight: "-4rem" }} className="popUpButtonDesktop">
                        Visit the collection
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </Modal.Content>
          </Modal>
        );
      } else {
        ////////////////////////////////////MOBILE//////////////////////////////////////////////////////////////////////////////////
        return (
          <Modal
            className="sharpCorners"
            style={{ height: "200vw", width: "100vw" }}
            onClose={() => this.setState((state) => ({ open: false }))}
            onOpen={() => this.setState((state) => ({ open: true }))}
            open={this.state.open}
            dimmer="blurring"
          >
            <a
              onClick={() => {
                this.setState(() => ({ open: false }));
              }}
            >
              <Image style={{ height: "3rem", float: "right" }} src={`assets/x.png`} />
            </a>
            <Modal.Content>
              <p
                style={{
                  fontSize: "2rem",
                  textAlign: "center",
                  marginTop: "2rem",
                }}
              >
                Congratulations!
              </p>
              <p
                style={{
                  fontSize: "1.5rem",
                  textAlign: "center",
                  marginTop: "2rem",
                  marginBottom: "1rem",
                }}
              >
                You received "{this.state.nftName}" <br />
                by {this.state.artistName}
              </p>
              <Image bordered={false} size="medium" src={`assets/${this.state.nftFileName}`} />

              <a
                style={{ color: "black" }}
                href={
                  "https://opensea.io/assets/matic/0xb314158f7c233c34757e925cf3ea157b44ca88a6/" +
                  this.props.token
                }
              >
                <div className="popUpButton">View your NFT in OpenSea</div>
              </a>
              <a
                style={{ color: "black" }}
                href="https://opensea.io/collection/future-is-an-attitude"
              >
                <div className="popUpButton">Visit the collection</div>
              </a>
            </Modal.Content>
          </Modal>
        );
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      }
    } else {
      if (!this.props.isMobile) {
        return (
          <Modal
            className="sharpCorners"
            style={{ height: "45vw", width: "50vw", maxWidth: "80rem", maxHeight: "66rem" }}
            onClose={() => this.setState((state) => ({ open: false }))}
            onOpen={() => this.setState((state) => ({ open: true }))}
            open={this.state.open}
            dimmer={"blurring"}
          >
            <a
              onClick={() => {
                this.setState(() => ({ open: false }));
              }}
            >
              <Image style={{ height: "3rem", float: "right" }} src={`assets/x.png`} />
            </a>
            <Modal.Content>
              <p
                style={{
                  fontSize: "38px",
                  textAlign: "center",
                  marginTop: "7rem",
                }}
              >
                Your NFT will <br />
                arrive to you in a <br />
                minute!
              </p>
              <p style={{ fontSize: "27px", textAlign: "center", marginTop: "4rem" }}></p>
              <div
                style={{
                  paddingRight: "2.45rem",
                  paddingLeft: "2.45rem",
                  paddingBottom: "2.45rem",
                  paddingTop: "3rem",
                  width: "16rem",
                  height: "16rem",
                  textAlign: "center",
                  margin: "auto",
                  marginTop: "8rem",
                  marginBottom: "6rem",
                  position: "relative",
                }}
              >
                {/* backgroundColor: "#f2f2f2",
                  width: "34rem",
                  height: "34rem",
                  textAlign: "center",
                  // marginLeft: "16rem",
                  // marginRight: "20rem",
                  marginTop: "2rem", */}

                <SpinnerCircularFixed
                  size={140}
                  thickness={100}
                  speed={117}
                  color="rgba(228, 58, 62, 1)"
                  secondaryColor="rgba(229, 229, 229, 1)"
                />
              </div>
            </Modal.Content>
          </Modal>
        );
      } else {
        {
          /* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILE MOBILEMOBILE MOBILEMOBILE
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
          return (
            <Modal
              className="sharpCorners"
              style={{ height: "10vw", width: "100vw", marginTop: "-50rem" }}
              onClose={() => this.setState((state) => ({ open: false }))}
              onOpen={() => this.setState((state) => ({ open: true }))}
              open={this.state.open}
              dimmer={"blurring"}
            >
              <a
                onClick={() => {
                  this.setState(() => ({ open: false }));
                }}
              >
                <Image
                  style={{ marginTop: "40px", height: "3rem", float: "right" }}
                  src={`assets/x.png`}
                />
              </a>
              <Modal.Content>
                <p
                  style={{
                    fontSize: "2rem",
                    textAlign: "center",
                    marginTop: "7rem",
                  }}
                >
                  Your NFT will <br />
                  arrive <br />
                  to you in a <br />
                  minute!
                </p>
                <p style={{ fontSize: "1.5rem", textAlign: "center", marginTop: "4rem" }}></p>
                <div
                  style={{
                    paddingRight: "2.45rem",
                    paddingLeft: "2.45rem",
                    paddingBottom: "2.45rem",
                    paddingTop: "2.45rem",
                    width: "35vw",
                    height: "35vw",
                    textAlign: "center",
                    marginLeft: "30vw",
                    marginRight: "20vw",
                    marginTop: "20vw",
                    marginBottom: "40vw",
                  }}
                >
                  <SpinnerCircularFixed
                    size={65}
                    thickness={100}
                    speed={117}
                    color="rgba(228, 58, 62, 1)"
                    secondaryColor="rgba(229, 229, 229, 1)"
                  />
                </div>
              </Modal.Content>
            </Modal>
          );
        }

        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      }
    }
  }
}
