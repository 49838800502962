import "./App.css";
import "./index.css";

import NavBar from "./NavBar";
import FirstPage from "./desktopPages/FirstPage";
import AboutPage from "./desktopPages/AboutPage";
import NFT from "./desktopPages/NFT";
import Help from "./desktopPages/Help";
import Footer from "./desktopPages/Footer";

import { useMediaQuery } from "react-responsive";

import MetamaskProvider, { INetwork } from "./Metamask/MetamaskProvider";
import Networks from "./Metamask/Networks";

async function connect() {
  if (MetamaskProvider.get() !== undefined)
    await MetamaskProvider.connect(Networks.matic as INetwork);
}
function App() {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
  connect();

  return (
    <div className="main">
      <div className="content">
        <NavBar />
        <FirstPage mobile={isTabletOrMobile} />
        <AboutPage />
        <NFT />
        <Help />
        <Footer />
      </div>
    </div>
  );
}

export default App;
