import { Image, Container, Header, Grid } from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";

interface Prop {
  imageName: string;
  creatorName: string;
  instagramWebLink: string;
  nftWebLink: string;
}
export default function SingleNftCreator({
  imageName,
  creatorName,
  instagramWebLink,
  nftWebLink,
}: Prop) {
  const goToInsta = () => {
    // window.location.href = instagramWebLink;
    window.open(instagramWebLink);
  };
  const goToNftPage = () => {
    window.open(nftWebLink);
  };
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  return (
    <Grid.Column>
      <Container textAlign="center">
        <a onClick={goToNftPage} style={{ cursor: "pointer" }} target="blank">
          <Image
            verticalAlign="middle"
            size="small"
            src={`assets/${imageName}.png`}
            style={{ marginTop: "3rem", marginBottom: "1rem" }}
          />
        </a>
        <br />
        {!isTabletOrMobile && (
          <p style={{ fontSize: "32px" }} className="nftInstaNames">
            {creatorName}
          </p>
        )}
        {isTabletOrMobile && (
          <p style={{ fontSize: "21px" }} className="nftInstaNames">
            {creatorName}
          </p>
        )}
        <br />
        <a onClick={goToInsta} style={{ cursor: "pointer" }} target="_blank">
          <Image verticalAlign="middle" className="instagram" src={"assets/instagram.png"} />
        </a>
      </Container>
    </Grid.Column>
  );
}
