import { ethers } from "ethers";
import Networks from "./Networks";
enum MetamaskEvents {
  AccountsChanged = "accountsChanged",
  ChainChanged = "chainChanged",
  Connect = "connect",
  Disconnect = "disconnect",
  Message = "message",
}

interface INetwork {
  chainId: string;
  name: string;
  host: string;
  explorer: string;
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: number;
  };
  rpcUrls: string[];
}

export type { INetwork, MetamaskEvents };

export default class MetamaskProvider {
  private _web3Provider!: ethers.providers.Web3Provider | undefined;
  private _web3Signer!: ethers.providers.JsonRpcSigner | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _ethereum!: any;

  private static instance: MetamaskProvider | undefined;

  static get(): MetamaskProvider {
    if (!this.instance) {
      this.instance = new MetamaskProvider();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.instance._ethereum = (window as any).ethereum;
      if (!this.instance._metamaskInstalled) {
        this.instance = undefined;
        // throw new Error("MetaMask is not installed");
        
      } 
    }
    if(this.instance?._web3Provider === undefined) {
      this.instance?._createProvider();
    }
    return this.instance as MetamaskProvider;
  }

  static connected(): boolean {
    const instance = this.get(); 
    if(instance ===  undefined) return false;

    return instance?._web3Provider !== undefined;
  }

  static async connect(network?: INetwork): Promise<MetamaskProvider> {
    const metamask = this.get();
    metamask.clear();
    await metamask._createProvider();
    if (network) await metamask._changeNetwork(network);
    return metamask;
  }

  private get _metamaskInstalled(): boolean {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this._ethereum !== undefined;
  }

  public get provider(): ethers.providers.Web3Provider {
    return this._web3Provider as ethers.providers.Web3Provider;
  }

  public get signer(): ethers.providers.JsonRpcSigner {
    return this._web3Signer as ethers.providers.JsonRpcSigner;
  }

  static provider(): ethers.providers.Web3Provider {
    const metamask = this.get();

    return metamask.provider as ethers.providers.Web3Provider;
  }

  static signer(): ethers.providers.JsonRpcSigner {
    const metamask = this.get(); 
    return metamask.signer as ethers.providers.JsonRpcSigner;

  }

  private async _createProvider(): Promise<void> { 
    this._web3Provider = new ethers.providers.Web3Provider(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this._ethereum,
      "any"
    );
    await this._web3Provider.send("eth_requestAccounts", []);
    this._ethereum.on("chainChanged", () => {
      window.location.reload();
    });
    this._ethereum.on("accountsChanged", () => {
      window.location.reload();
    });
    this._ethereum.on("disconnect", () => {
      window.location.reload();
    });
    this._web3Signer = this._web3Provider.getSigner();
    //@TODO can bind events, but I don't need them
  }

  private async _changeNetwork(network: INetwork): Promise<void> {
    if (!this._web3Provider) return undefined; 
    if("0x" + (await (this._web3Provider.getNetwork())).chainId.toString(16) === network.chainId) return;
    try {
      // check if the chain to connect to is installed
      await this._web3Provider.send(
        "wallet_switchEthereumChain",
        [{ chainId: network.chainId }] // chainId must be in hexadecimal numbers
      );
    } catch (error) {
      try { 
        console.log(network);
        await this._web3Provider.send("wallet_addEthereumChain", [
          {
            chainId: network.chainId,
            chainName: network.name,
            nativeCurrency: network.nativeCurrency,
            rpcUrls: network.rpcUrls,
            blockExplorerUrls: [network.explorer],
          },
        ]);
        // check if the chain to connect to is installed
        await this._web3Provider.send(
          "wallet_switchEthereumChain",
          [{ chainId: network.chainId }] // chainId must be in hexadecimal numbers
        );
      } catch (addError) {
        console.error(error);
        console.error(addError);
        throw new Error(addError as string);
      }
    }
    window.location.reload();
  }

  static async changeNetwork(network: INetwork): Promise<void> {
    const metamask = this.get(); 
    return await metamask._changeNetwork(network); 

  }
  public clear(): void {
    this._web3Provider = undefined;
    this._web3Signer = undefined;
    this._ethereum?.removeAllListeners();
  }

  static setEvent(type: MetamaskEvents, func: (...args: []) => void): void {
    const instance = this.get();
    instance._ethereum.on(MetamaskEvents.Connect, func);
  }

  static async getNetwork(): Promise<INetwork | undefined> {
    const provider = this.provider();
    const providerNetwork = await provider.getNetwork();
    const found = Object.values(Networks).find((network) => {
      return parseInt(network.chainId, 16) === providerNetwork.chainId;
    });
    return found ? (found as INetwork) : undefined;
  }
}
